import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { message } from 'antd'

import { updateProductPromise } from '../services'
import { handleError } from '../../../helpers'

export default function EditProductPromiseModal({ visible, onClose, productPromise, reload }) {
	const { handleSubmit, register, errors, reset } = useForm()

	const onSubmit = values => {
		updateProductPromise(productPromise.id, values)
			.then(() => {
				message.success('Promesa de entrega actualizada exitosamente')
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Promesa de Entrega</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Producto</Form.Label>
							<Form.Control disabled
								defaultValue={productPromise && productPromise.keyword}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Días para Medidas Estandar <span className="text-primary">*</span></Form.Label>
							<Form.Control 
								type="number"
								name="regular_size"
								ref={register({ required: true })}
								defaultValue={productPromise && productPromise.regular_size}
							/>
							{ errors.regular_size && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Días para Medidas Especiales <span className="text-primary">*</span></Form.Label>
							<Form.Control 
								type="number"
								name="custom_size"
								ref={register({ required: true })}
								defaultValue={productPromise && productPromise.custom_size}
							/>
							{ errors.custom_size && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button type="submit" color="primary">Actualizar Promesa de Entrega</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}