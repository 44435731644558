import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {Card, CardBody, CardHeader, CardTitle, CardSubtitle, Row, Col, Button} from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'
import { DatePicker, Divider, message } from 'antd'
import moment from 'moment'

import SurveysReport from './partials/SurveysReport'
import SurveysTable from './partials/SurveysTable'

import { getSurveys } from './services'
import { handleError } from '../../helpers'
import { storeExportToken } from '../ExportTokens/services'

export default function SurveysList() {
	const { user } = useSelector(state => state.auth)
	const [surveys, setSurveys] = useState(null)
	const [stats, setStats] = useState(null)
	const [from, setFrom] = useState(moment().startOf('month'))
	const [to, setTo] = useState(moment().endOf('month'))
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterType, setFilterType] = useState('score')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!surveys && getSurveys({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'deliveryOrder,deliveryCustomer',
			'filter[created_between]': `${moment(from).format('YYYY-MM-DD 00:00:00')},${moment(to).format('YYYY-MM-DD 23:59:59')}`
		})
			.then(res => {
				setSurveys(res.data.surveys)
				setStats(res.data.stats)
			})
			.catch(handleError)
	}, [surveys, pagination, filterType, filterValue, from, to])

	const onChange = dates => {
		setFrom(dates[0])
		setTo(dates[1])
		setSurveys(null)
	}

	const onExport = () => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000)

		storeExportToken({ user_id: user.id, type: 'Exportar Encuestas', token })
			.then(() => {
				message.info('Descarga en iniciada correctamente')
				window.open(`https://apientregas.spring.com.co/exports/surveys/${token}`)
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Card>
						<CardHeader>
							<CardTitle>Periodo de Consulta:</CardTitle>
							<CardSubtitle>Eliga el periodo de encuestas a consultar</CardSubtitle>
						</CardHeader>
						<CardBody>
							<DatePicker.RangePicker
								ranges={{
									'Hoy': [moment(), moment()],
									'Este mes': [moment().startOf('month'), moment().endOf('month')],
									'Este año': [moment().startOf('year'), moment().endOf('year')],
								}}
								onChange={onChange}
								style={{ width: '100%' }}
								placeholder={['Fecha de Inicio', 'Fecha Final']}
								value={[from, to]}
							/>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>Reporte de Encuestas</CardTitle>
							<CardSubtitle>Resultado de encuestas de satifacción</CardSubtitle>
						</CardHeader>
						<CardBody>
							<SurveysReport stats={stats} />
						</CardBody>
					</Card>
				</Col>
				<Col md="8">
					<Card>
						<CardHeader>
							<div className="float-right mt-2">
								<InputGroup>
									<InputGroup.Prepend>
										<Form.Control 
											as="select" 
											className="bg-light"
											value={filterType}
											onChange={e => setFilterType(e.target.value)}
										>
											<option value="score">Buscar por puntaje</option>
										</Form.Control>
									</InputGroup.Prepend>
									<Form.Control 
										placeholder="Escriba aquí" 
										value={filterValue}
										onChange={e => setFilterValue(e.target.value)}
									/>
									<InputGroup.Append>
										<Button color="primary" onClick={()=>setSurveys(null)}>Buscar</Button>
									</InputGroup.Append>
									<Divider type="vertical" />
									<Button color="dark" onClick={onExport}>Exportar</Button>
								</InputGroup>
							</div>
							<CardTitle>Listado de Encuestas</CardTitle>
							<CardSubtitle>Encuestas diligenciadas por clientes</CardSubtitle>
						</CardHeader>
						<CardBody>
							<SurveysTable 
								surveys={surveys} 
								reload={()=>setSurveys(null)}
								pagination={pagination}
								setPagination={setPagination}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}