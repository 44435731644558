import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import { storeExportToken } from '../../ExportTokens/services'
import { handleError } from '../../../helpers'

export default function DeliveriesExportModal({ visible, onClose }) {
	const { user } = useSelector(state => state.auth)
	const [from, setFrom] = useState(moment().subtract(1, 'M').format('YYYY-MM-DD'))
	const [to, setTo] = useState(moment().format('YYYY-MM-DD'))

	const onSubmit = () => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000)

		storeExportToken({ user_id: user.id, type: 'Exportar Entregas', token })
			.then(() => {
				message.info('Descarga en iniciada correctamente')
				window.open(`https://apientregas.spring.com.co/exports/deliveries/${from}/${to}/${token}`)
				onClose()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Exportar Entregas</ModalHeader>
				<ModalBody>
					<Form.Group>
						<Form.Label>Fecha de entrega desde: </Form.Label>
						<Form.Control 
							type="date"
							value={from}
							onChange={e => setFrom(e.target.value)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Fecha de entrega hasta: </Form.Label>
						<Form.Control 
							type="date"
							value={to}
							onChange={e => setTo(e.target.value)}
						/>
					</Form.Group>
					<Button 
						color="primary" 
						onClick={onSubmit}
						disabled={(!from || !to)}
					>
						Exportar Entregas
					</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}