import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import BannedDaysList from './BannedDaysList'

export default function BannedDays() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/banned_days" component={BannedDaysList} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}