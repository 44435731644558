import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Rate } from 'antd'

import AuthLayout from '../../layouts/AuthLayout'
import { storePublicSurvey } from './services'
import { handleError } from '../../helpers'

const desc = ['Insatisfecho', 'Malo', 'Normal', 'Bueno', 'Excelente']

export default function NewSurvey(props) {
	const deliveryId = props.match.params.deliveryId
	const { handleSubmit, register, errors, reset } = useForm()
	const [score, setScore] = useState(4)

	const onSubmit = values => {		
		storePublicSurvey({
			delivery_id: deliveryId,
			responses: JSON.stringify({ 'Comentario del Cliente': values.comment }),
			score
		})
			.then(() => {
				message.success('Gracias por su respuesta.')
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<AuthLayout>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<h6>¿Cómo describe el servicio recibido en la entrega de sus productos Spring?</h6>
				<Form.Group>
					<Rate 
						tooltips={desc} 
						onChange={value => setScore(value)} 
						value={score} 
						allowClear={false}
					/>
					{score ? <span className="ant-rate-text">{desc[score - 1]}</span> : ''}
				</Form.Group>
				<Form.Group>
					<Form.Label>Comentario <span className="text-danger">*</span></Form.Label>
					<Form.Control
						as="textarea"
						name="comment"
						ref={register({ required:true })}
					/>
					{ errors.comment && <Form.Text className="text-warning">Favor ingrese un comentario</Form.Text> }
				</Form.Group>
				<Button color="primary" className="full-width">Enviar Encuesta</Button>
			</Form>
		</AuthLayout>
	)
}