import React from 'react'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { message } from 'antd'

import CityRouteForm from './CityRouteForm'
import { updateCityRoute } from '../services'
import { handleError } from '../../../helpers'

export default function EditCityRouteModal({ visible, onClose, cityRoute, reload }) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		updateCityRoute(cityRoute.id, values)
			.then(() => {
				message.success('Ruta actualizada exitosamente')
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Ruta de Entrega</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<CityRouteForm 
							register={register} 
							errors={errors} 
							cityRoute={cityRoute}
						/>
						<Button color="primary" type="submit">Actualizar Ruta</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}