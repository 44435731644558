import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function CityRouteForm({ register, errors, cityRoute }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Ciudad <span className="text-primary">*</span></Form.Label>
				{ !cityRoute ? (
					<Form.Control
						as="select"
						name="city"
						ref={register({ required: true })}
						defaultValue={cityRoute && cityRoute.city}
					>
						{ citiesAndState.map( city => <option>{city}</option>) }
					</Form.Control>
				) : (
					<Form.Control value={cityRoute.city} disabled />
				)}
				{ errors.city && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>País <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						as="select"
						name="country"
						ref={register({ required: true })}
						defaultValue={cityRoute && cityRoute.country}
					>
						<option value="COLOMBIA">CO - COLOMBIA</option>
					</Form.Control>
					{ errors.country && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Regional <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						as="select"
						name="region"
						ref={register({ required: true })}
						defaultValue={cityRoute && cityRoute.region}
					>
						<option>BOGOTA</option>
						<option>ANTIOQUIA</option>
						<option>CENTRO</option>
						<option>COSTA</option>
						<option>EJE CAFETERO</option>
						<option>OCCIDENTE</option>
						<option>PERIFERIA</option>
						<option>SUR OCCIDENTE</option>
						<option>SANTANDERES</option>
					</Form.Control>
					{ errors.region && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Código de Ruta</Form.Label>
					<Form.Control 
						name="code"
						ref={register({})}
						defaultValue={cityRoute && cityRoute.code}
					/>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Descripción</Form.Label>
				<Form.Control 
					name="description"
					ref={register({})}
					defaultValue={cityRoute && cityRoute.description}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Observación</Form.Label>
				<Form.Control 
					name="comment"
					ref={register({})}
					defaultValue={cityRoute && cityRoute.comment}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Observación de la Frecuencia</Form.Label>
				<Form.Control 
					name="frecuency_comment"
					ref={register({})}
					defaultValue={cityRoute && cityRoute.frecuency_comment}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Check 
					type="checkbox"
					name="monday"
					ref={register({})}
					label="Lunes"
					inline
					defaultChecked={cityRoute && cityRoute.monday}
				/>
				<Form.Check 
					type="checkbox"
					name="tuesday"
					ref={register({})}
					label="Martes"
					inline
					defaultChecked={cityRoute && cityRoute.tuesday}
				/>
				<Form.Check 
					type="checkbox"
					name="wednesday"
					ref={register({})}
					label="Miércoles"
					inline
					defaultChecked={cityRoute && cityRoute.wednesday}
				/>
				<Form.Check 
					type="checkbox"
					name="thursday"
					ref={register({})}
					label="Jueves"
					inline
					defaultChecked={cityRoute && cityRoute.thursday}
				/>
				<Form.Check 
					type="checkbox"
					name="friday"
					ref={register({})}
					label="Viernes"
					inline
					defaultChecked={cityRoute && cityRoute.friday}
				/>
				<Form.Check 
					type="checkbox"
					name="saturday"
					ref={register({})}
					label="Sábado"
					inline
					defaultChecked={cityRoute && cityRoute.saturday}
				/>
				<Form.Check 
					type="checkbox"
					name="sunday"
					ref={register({})}
					label="Domingo"
					inline
					defaultChecked={cityRoute && cityRoute.sunday}
				/>
			</Form.Group>
			{/* <Row>
				<Form.Group as={Col}>
					<Form.Label>Tarifas <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						name="rates"
						ref={register({ required: true })}
						defaultValue={cityRoute && cityRoute.rates}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Tarifa Extra <small>(Opcional)</small></Form.Label>
					<Form.Control 
						name="extra_rate"
						ref={register({})}
						defaultValue={cityRoute && cityRoute.extra_rate}
					/>
				</Form.Group>
			</Row> */}
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Origen</Form.Label>
					<Form.Control 
						as="select"
						name="origin"
						ref={register({})}
						defaultValue={cityRoute && cityRoute.origin}
					>
						<option value="">:: Sin registro ::</option>
						<option>DETALLADO 1</option>
						<option>DETALLADO 2</option>
						<option>DETALLADO 3</option>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Días Adicionales en Regional</Form.Label>
					<Form.Control
						type="number" 
						name="regional_stop"
						ref={register({})}
						label="Con parada en regional"
						defaultValue={cityRoute && cityRoute.regional_stop}
					/>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Check
					name="pay_on_delivery"
					ref={register({})}
					label="Pago contra entrega"
					inline
					defaultChecked={cityRoute && cityRoute.pay_on_delivery}
				/>
			</Form.Group>
		</React.Fragment>
	)
}

const citiesAndState = [
	"ABEJORRAL, ANTIOQUIA",
"ABREGO, NORTE DE SANTANDER",
"ABRIAQUÍ, ANTIOQUIA",
"ACACIAS, META",
"ACANDÍ, CHOCO",
"ACEVEDO, HUILA",
"ACHÍ, BOLIVAR",
"AGRADO, HUILA",
"AGUA DE DIOS, CUNDINAMARCA",
"AGUACHICA, CESAR",
"AGUADA, SANTANDER",
"AGUADAS, CALDAS",
"AGUAZUL, CASANARE",
"AGUSTÍN CODAZZI, CESAR",
"AIPE, HUILA",
"ALBAN, NARIÑO",
"ALBÁN, CUNDINAMARCA",
"ALBANIA, CAQUETA",
"ALBANIA, LA GUAJIRA",
"ALBANIA, SANTANDER",
"ALCALA, VALLE DEL CAUCA",
"ALDANA, NARIÑO",
"ALEJANDRÍA, ANTIOQUIA",
"ALGARROBO, MAGDALENA",
"ALGECIRAS, HUILA",
"ALMAGUER, CAUCA",
"ALMEIDA, BOYACÁ",
"ALPUJARRA, TOLIMA",
"ALTAMIRA, HUILA",
"ALTO BAUDÓ, CHOCO",
"ALTOS DEL ROSARIO, BOLIVAR",
"ALVARADO, TOLIMA",
"AMAGA, ANTIOQUIA",
"AMALFI, ANTIOQUIA",
"AMBALEMA, TOLIMA",
"ANAPOIMA, CUNDINAMARCA",
"ANCUYA, NARIÑO",
"ANDALUCÍA, VALLE DEL CAUCA",
"ANDES, ANTIOQUIA",
"ANGELOPOLIS, ANTIOQUIA",
"ANGOSTURA, ANTIOQUIA",
"ANOLAIMA, CUNDINAMARCA",
"ANORÍ, ANTIOQUIA",
"ANSERMA, CALDAS",
"ANSERMANUEVO, VALLE DEL CAUCA",
"ANZA, ANTIOQUIA",
"ANZOÁTEGUI, TOLIMA",
"APARTADÓ, ANTIOQUIA",
"APÍA, RISARALDA",
"APULO, CUNDINAMARCA",
"AQUITANIA, BOYACÁ",
"ARACATACA, MAGDALENA",
"ARANZAZU, CALDAS",
"ARATOCA, SANTANDER",
"ARAUCA, ARAUCA",
"ARAUQUITA, ARAUCA",
"ARBELÁEZ, CUNDINAMARCA",
"ARBOLEDA, NARIÑO",
"ARBOLEDAS, NORTE DE SANTANDER",
"ARBOLETES, ANTIOQUIA",
"ARCABUCO, BOYACÁ",
"ARENAL, BOLIVAR",
"ARGELIA, ANTIOQUIA",
"ARGELIA, CAUCA",
"ARGELIA, VALLE DEL CAUCA",
"ARIGUANÍ, MAGDALENA",
"ARJONA, BOLIVAR",
"ARMENIA, ANTIOQUIA",
"ARMENIA, QUINDIO",
"ARMERO, TOLIMA",
"ARROYOHONDO, BOLIVAR",
"ASTREA, CESAR",
"ATACO, TOLIMA",
"ATRATO, CHOCO",
"AYAPEL, CORDOBA",
"BAGADÓ, CHOCO",
"BAHÍA SOLANO, CHOCO",
"BAJO BAUDÓ, CHOCO",
"BALBOA, RISARALDA",
"BALBOA, CAUCA",
"BARANOA, ATLÁNTICO",
"BARAYA, HUILA",
"BARBACOAS, NARIÑO",
"BARBOSA, ANTIOQUIA",
"BARBOSA, SANTANDER",
"BARICHARA, SANTANDER",
"BARRANCA DE UPIA, META",
"BARRANCABERMEJA, SANTANDER",
"BARRANCAS, LA GUAJIRA",
"BARRANCO DE LOBA, BOLIVAR",
"BARRANCO MINA, GUAINIA",
"BARRANQUILLA, ATLÁNTICO",
"BECERRIL, CESAR",
"BELALCÁZAR, CALDAS",
"BELÉN, BOYACÁ",
"BELEN, NARIÑO",
"BELÉN DE BAJIRA, CHOCO",
"BELÉN DE LOS ANDAQUIES, CAQUETA",
"BELÉN DE UMBRÍA, RISARALDA",
"BELLO, ANTIOQUIA",
"BELMIRA, ANTIOQUIA",
"BELTRÁN, CUNDINAMARCA",
"BERBEO, BOYACÁ",
"BETANIA, ANTIOQUIA",
"BETÉITIVA, BOYACÁ",
"BETULIA, ANTIOQUIA",
"BETULIA, SANTANDER",
"BITUIMA, CUNDINAMARCA",
"BOAVITA, BOYACÁ",
"BOCHALEMA, NORTE DE SANTANDER",
"BOGOTA D.C, BOGOTÁ D.C.",
"BOJACÁ, CUNDINAMARCA",
"BOJAYA, CHOCO",
"BOLÍVAR, CAUCA",
"BOLÍVAR, VALLE DEL CAUCA",
"BOLÍVAR, SANTANDER",
"BOSCONIA, CESAR",
"BOYACÁ, BOYACÁ",
"BRICEÑO, ANTIOQUIA",
"BRICEÑO, BOYACÁ",
"BUCARAMANGA, SANTANDER",
"BUCARASICA, NORTE DE SANTANDER",
"BUENAVENTURA, VALLE DEL CAUCA",
"BUENAVISTA, QUINDIO",
"BUENAVISTA, SUCRE",
"BUENAVISTA, BOYACÁ",
"BUENAVISTA, CORDOBA",
"BUENOS AIRES, CAUCA",
"BUESACO, NARIÑO",
"BUGA, VALLE DEL CAUCA",
"BUGALAGRANDE, VALLE DEL CAUCA",
"BURITICÁ, ANTIOQUIA",
"BUSBANZÁ, BOYACÁ",
"CABRERA, SANTANDER",
"CABRERA, CUNDINAMARCA",
"CABUYARO, META",
"CACAHUAL, GUAINIA",
"CÁCERES, ANTIOQUIA",
"CACHIPAY, CUNDINAMARCA",
"CACHIRÁ, NORTE DE SANTANDER",
"CÁCOTA, NORTE DE SANTANDER",
"CAICEDO, ANTIOQUIA",
"CAICEDONIA, VALLE DEL CAUCA",
"CAIMITO, SUCRE",
"CAJAMARCA, TOLIMA",
"CAJIBÍO, CAUCA",
"CAJICÁ, CUNDINAMARCA",
"CALAMAR, GUAVIARE",
"CALAMAR, BOLIVAR",
"CALARCA, QUINDIO",
"CALDAS, ANTIOQUIA",
"CALDAS, BOYACÁ",
"CALDONO, CAUCA",
"CALI, VALLE DEL CAUCA",
"CALIFORNIA, SANTANDER",
"CALIMA, VALLE DEL CAUCA",
"CALOTO, CAUCA",
"CAMPAMENTO, ANTIOQUIA",
"CAMPO DE LA CRUZ, ATLÁNTICO",
"CAMPOALEGRE, HUILA",
"CAMPOHERMOSO, BOYACÁ",
"CANALETE, CORDOBA",
"CAÑASGORDAS, ANTIOQUIA",
"CANDELARIA, ATLÁNTICO",
"CANDELARIA, VALLE DEL CAUCA",
"CANTAGALLO, BOLIVAR",
"CANTON DE SAN PABLO, CHOCO",
"CAPARRAPÍ, CUNDINAMARCA",
"CAPITANEJO, SANTANDER",
"CAQUEZA, CUNDINAMARCA",
"CARACOLÍ, ANTIOQUIA",
"CARAMANTA, ANTIOQUIA",
"CARCASÍ, SANTANDER",
"CAREPA, ANTIOQUIA",
"CARMEN DE APICALÁ, TOLIMA",
"CARMEN DE BOLÍVAR, BOLIVAR",
"CARMEN DE CARUPA, CUNDINAMARCA",
"CARMEN DE VIBORAL, ANTIOQUIA",
"CARMÉN DEL DARIÉN, CHOCO",
"CAROLINA, ANTIOQUIA",
"CARTAGENA, BOLIVAR",
"CARTAGENA DEL CHAIRÁ, CAQUETA",
"CARTAGO, VALLE DEL CAUCA",
"CARURU, VAUPES",
"CASABIANCA, TOLIMA",
"CASTILLA LA NUEVA, META",
"CAUCASIA, ANTIOQUIA",
"CEPITÁ, SANTANDER",
"CERETÉ, CORDOBA",
"CERINZA, BOYACÁ",
"CERRITO, SANTANDER",
"CERRO SAN ANTONIO, MAGDALENA",
"CERTEGUI, CHOCO",
"CHACHAGUI, NARIÑO",
"CHAGUANÍ, CUNDINAMARCA",
"CHALÁN, SUCRE",
"CHAMEZA, CASANARE",
"CHAPARRAL, TOLIMA",
"CHARALÁ, SANTANDER",
"CHARTA, SANTANDER",
"CHÍA, CUNDINAMARCA",
"CHIBOLO, MAGDALENA",
"CHIGORODÓ, ANTIOQUIA",
"CHIMÁ, CORDOBA",
"CHIMA, SANTANDER",
"CHIMICHAGUA, CESAR",
"CHINÁCOTA, NORTE DE SANTANDER",
"CHINAVITA, BOYACÁ",
"CHINCHINA, CALDAS",
"CHINÚ, CORDOBA",
"CHIPAQUE, CUNDINAMARCA",
"CHIPATÁ, SANTANDER",
"CHIQUINQUIRÁ, BOYACÁ",
"CHÍQUIZA, BOYACÁ",
"CHIRIGUANA, CESAR",
"CHISCAS, BOYACÁ",
"CHITA, BOYACÁ",
"CHITAGÁ, NORTE DE SANTANDER",
"CHITARAQUE, BOYACÁ",
"CHIVATÁ, BOYACÁ",
"CHIVOR, BOYACÁ",
"CHOACHÍ, CUNDINAMARCA",
"CHOCONTÁ, CUNDINAMARCA",
"CICUCO, BOLIVAR",
"CIÉNAGA, MAGDALENA",
"CIÉNAGA DE ORO, CORDOBA",
"CIÉNEGA, BOYACÁ",
"CIMITARRA, SANTANDER",
"CIRCASIA, QUINDIO",
"CISNEROS, ANTIOQUIA",
"CIUDAD BOLÍVAR, ANTIOQUIA",
"CLEMENCIA, BOLIVAR",
"COCORNÁ, ANTIOQUIA",
"COELLO, TOLIMA",
"COGUA, CUNDINAMARCA",
"COLOMBIA, HUILA",
"COLON, NARIÑO",
"COLÓN, PUTUMAYO",
"COLOSO, SUCRE",
"CÓMBITA, BOYACÁ",
"CONCEPCIÓN, ANTIOQUIA",
"CONCEPCIÓN, SANTANDER",
"CONCORDIA, MAGDALENA",
"CONCORDIA, ANTIOQUIA",
"CONDOTO, CHOCO",
"CONFINES, SANTANDER",
"CONSACA, NARIÑO",
"CONTADERO, NARIÑO",
"CONTRATACIÓN, SANTANDER",
"CONVENCIÓN, NORTE DE SANTANDER",
"COPACABANA, ANTIOQUIA",
"COPER, BOYACÁ",
"CÓRDOBA, BOLIVAR",
"CÓRDOBA, NARIÑO",
"CORDOBA, QUINDIO",
"CORINTO, CAUCA",
"COROMORO, SANTANDER",
"COROZAL, SUCRE",
"CORRALES, BOYACÁ",
"COTA, CUNDINAMARCA",
"COTORRA, CORDOBA",
"COVARACHÍA, BOYACÁ",
"COVEÑAS, SUCRE",
"COYAIMA, TOLIMA",
"CRAVO NORTE, ARAUCA",
"CUASPUD, NARIÑO",
"CUBARÁ, BOYACÁ",
"CUCAITA, BOYACÁ",
"CUCUNUBÁ, CUNDINAMARCA",
"CÚCUTA, NORTE DE SANTANDER",
"CUCUTILLA, NORTE DE SANTANDER",
"CUÍTIVA, BOYACÁ",
"CUMARAL, META",
"CUMARIBO, VICHADA",
"CUMBAL, NARIÑO",
"CUMBITARA, NARIÑO",
"CUNDAY, TOLIMA",
"CURITÍ, SANTANDER",
"CURRILLO, CAQUETA",
"CURUMANÍ, CESAR",
"DABEIBA, ANTIOQUIA",
"DAGUA, VALLE DEL CAUCA",
"DIBULLA, LA GUAJIRA",
"DISTRACCION, LA GUAJIRA",
"DOLORES, TOLIMA",
"DON MATIAS, ANTIOQUIA",
"DOSQUEBRADAS, RISARALDA",
"DUITAMA, BOYACÁ",
"DURANIA, NORTE DE SANTANDER",
"EBÉJICO, ANTIOQUIA",
"EL ÁGUILA, VALLE DEL CAUCA",
"EL BAGRE, ANTIOQUIA",
"EL BANCO, MAGDALENA",
"EL CAIRO, VALLE DEL CAUCA",
"EL CALVARIO, META",
"EL CARMEN, NORTE DE SANTANDER",
"EL CARMEN DE ATRATO, CHOCO",
"EL CARMEN DE CHUCURÍ, SANTANDER",
"EL CASTILLO, META",
"EL CERRITO, VALLE DEL CAUCA",
"EL CHARCO, NARIÑO",
"EL COCUY, BOYACÁ",
"EL COLEGIO, CUNDINAMARCA",
"EL COPEY, CESAR",
"EL DONCELLO, CAQUETA",
"EL DORADO, META",
"EL DOVIO, VALLE DEL CAUCA",
"EL ENCANTO, AMAZONAS",
"EL ESPINO, BOYACÁ",
"EL GUACAMAYO, SANTANDER",
"EL GUAMO, BOLIVAR",
"EL LITORAL DEL SAN JUAN, CHOCO",
"EL MOLINO, LA GUAJIRA",
"EL PASO, CESAR",
"EL PAUJIL, CAQUETA",
"EL PEÑOL, NARIÑO",
"EL PEÑÓN, CUNDINAMARCA",
"EL PEÑON, BOLIVAR",
"EL PEÑÓN, SANTANDER",
"EL PIÑON, MAGDALENA",
"EL PLAYÓN, SANTANDER",
"EL RETEN, MAGDALENA",
"EL RETORNO, GUAVIARE",
"EL ROBLE, SUCRE",
"EL ROSAL, CUNDINAMARCA",
"EL ROSARIO, NARIÑO",
"EL TABLON DE GOMEZ, NARIÑO",
"EL TAMBO, NARIÑO",
"EL TAMBO, CAUCA",
"EL TARRA, NORTE DE SANTANDER",
"EL ZULIA, NORTE DE SANTANDER",
"ELÍAS, HUILA",
"ENCINO, SANTANDER",
"ENCISO, SANTANDER",
"ENTRERRIOS, ANTIOQUIA",
"ENVIGADO, ANTIOQUIA",
"ESPINAL, TOLIMA",
"FACATATIVÁ, CUNDINAMARCA",
"FALAN, TOLIMA",
"FILADELFIA, CALDAS",
"FILANDIA, QUINDIO",
"FIRAVITOBA, BOYACÁ",
"FLANDES, TOLIMA",
"FLORENCIA, CAQUETA",
"FLORENCIA, CAUCA",
"FLORESTA, BOYACÁ",
"FLORIÁN, SANTANDER",
"FLORIDA, VALLE DEL CAUCA",
"FLORIDABLANCA, SANTANDER",
"FOMEQUE, CUNDINAMARCA",
"FONSECA, LA GUAJIRA",
"FORTUL, ARAUCA",
"FOSCA, CUNDINAMARCA",
"FRANCISCO PIZARRO, NARIÑO",
"FREDONIA, ANTIOQUIA",
"FRESNO, TOLIMA",
"FRONTINO, ANTIOQUIA",
"FUENTE DE ORO, META",
"FUNDACION, MAGDALENA",
"FUNES, NARIÑO",
"FUNZA, CUNDINAMARCA",
"FÚQUENE, CUNDINAMARCA",
"FUSAGASUGÁ, CUNDINAMARCA",
"GACHALA, CUNDINAMARCA",
"GACHANCIPÁ, CUNDINAMARCA",
"GACHANTIVÁ, BOYACÁ",
"GACHETA, CUNDINAMARCA",
"GALÁN, SANTANDER",
"GALAPA, ATLÁNTICO",
"GALERAS, SUCRE",
"GAMA, CUNDINAMARCA",
"GAMARRA, CESAR",
"GAMBITA, SANTANDER",
"GAMEZA, BOYACÁ",
"GARAGOA, BOYACÁ",
"GARZÓN, HUILA",
"GENOVA, QUINDIO",
"GIGANTE, HUILA",
"GINEBRA, VALLE DEL CAUCA",
"GIRALDO, ANTIOQUIA",
"GIRARDOT, CUNDINAMARCA",
"GIRARDOTA, ANTIOQUIA",
"GIRÓN, SANTANDER",
"GÓMEZ PLATA, ANTIOQUIA",
"GONZÁLEZ, CESAR",
"GRAMALOTE, NORTE DE SANTANDER",
"GRANADA, CUNDINAMARCA",
"GRANADA, META",
"GRANADA, ANTIOQUIA",
"GUACA, SANTANDER",
"GUACAMAYAS, BOYACÁ",
"GUACARÍ, VALLE DEL CAUCA",
"GUACHETÁ, CUNDINAMARCA",
"GUACHUCAL, NARIÑO",
"GUADALUPE, SANTANDER",
"GUADALUPE, HUILA",
"GUADALUPE, ANTIOQUIA",
"GUADUAS, CUNDINAMARCA",
"GUAITARILLA, NARIÑO",
"GUALMATAN, NARIÑO",
"GUAMAL, MAGDALENA",
"GUAMAL, META",
"GUAMO, TOLIMA",
"GUAPI, CAUCA",
"GUAPOTÁ, SANTANDER",
"GUARANDA, SUCRE",
"GUARNE, ANTIOQUIA",
"GUASCA, CUNDINAMARCA",
"GUATAPE, ANTIOQUIA",
"GUATAQUÍ, CUNDINAMARCA",
"GUATAVITA, CUNDINAMARCA",
"GUATEQUE, BOYACÁ",
"GUÁTICA, RISARALDA",
"GUAVATÁ, SANTANDER",
"GUAYABAL DE SIQUIMA, CUNDINAMARCA",
"GUAYABETAL, CUNDINAMARCA",
"GUAYATÁ, BOYACÁ",
"GUEPSA, SANTANDER",
"GÜICÁN, BOYACÁ",
"GUTIÉRREZ, CUNDINAMARCA",
"HACARÍ, NORTE DE SANTANDER",
"HATILLO DE LOBA, BOLIVAR",
"HATO, SANTANDER",
"HATO COROZAL, CASANARE",
"HATONUEVO, LA GUAJIRA",
"HELICONIA, ANTIOQUIA",
"HERRÁN, NORTE DE SANTANDER",
"HERVEO, TOLIMA",
"HISPANIA, ANTIOQUIA",
"HOBO, HUILA",
"HONDA, TOLIMA",
"IBAGUE, TOLIMA",
"ICONONZO, TOLIMA",
"ILES, NARIÑO",
"IMUES, NARIÑO",
"INÍRIDA, GUAINIA",
"INZÁ, CAUCA",
"IPIALES, NARIÑO",
"IQUIRA, HUILA",
"ISNOS, HUILA",
"ITAGUI, ANTIOQUIA",
"ITSMINA, CHOCO",
"ITUANGO, ANTIOQUIA",
"IZA, BOYACÁ",
"JAMBALO, CAUCA",
"JAMUNDÍ, VALLE DEL CAUCA",
"JARDÍN, ANTIOQUIA",
"JENESANO, BOYACÁ",
"JERICÓ, ANTIOQUIA",
"JERICÓ, BOYACÁ",
"JERUSALÉN, CUNDINAMARCA",
"JESÚS MARÍA, SANTANDER",
"JORDÁN, SANTANDER",
"JUAN DE ACOSTA, ATLÁNTICO",
"JUNÍN, CUNDINAMARCA",
"JURADÓ, CHOCO",
"LA APARTADA, CORDOBA",
"LA ARGENTINA, HUILA",
"LA BELLEZA, SANTANDER",
"LA CALERA, CUNDINAMARCA",
"LA CAPILLA, BOYACÁ",
"LA CEJA, ANTIOQUIA",
"LA CELIA, RISARALDA",
"LA CHORRERA, AMAZONAS",
"LA CRUZ, NARIÑO",
"LA CUMBRE, VALLE DEL CAUCA",
"LA DORADA, CALDAS",
"LA ESPERANZA, NORTE DE SANTANDER",
"LA ESTRELLA, ANTIOQUIA",
"LA FLORIDA, NARIÑO",
"LA GLORIA, CESAR",
"LA GUADALUPE, GUAINIA",
"LA JAGUA DE IBIRICO, CESAR",
"LA JAGUA DEL PILAR, LA GUAJIRA",
"LA LLANADA, NARIÑO",
"LA MACARENA, META",
"LA MERCED, CALDAS",
"LA MESA, CUNDINAMARCA",
"LA MONTAÑITA, CAQUETA",
"LA PALMA, CUNDINAMARCA",
"LA PAZ, CESAR",
"LA PAZ, SANTANDER",
"LA PEDRERA, AMAZONAS",
"LA PEÑA, CUNDINAMARCA",
"LA PINTADA, ANTIOQUIA",
"LA PLATA, HUILA",
"LA PLAYA, NORTE DE SANTANDER",
"LA PRIMAVERA, VICHADA",
"LA SALINA, CASANARE",
"LA SIERRA, CAUCA",
"LA TEBAIDA, QUINDIO",
"LA TOLA, NARIÑO",
"LA UNION, NARIÑO",
"LA UNIÓN, SUCRE",
"LA UNIÓN, VALLE DEL CAUCA",
"LA UNIÓN, ANTIOQUIA",
"LA URIBE, META",
"LA UVITA, BOYACÁ",
"LA VEGA, CAUCA",
"LA VEGA, CUNDINAMARCA",
"LA VICTORIA, BOYACÁ",
"LA VICTORIA, VALLE DEL CAUCA",
"LA VICTORIA, AMAZONAS",
"LA VIRGINIA, RISARALDA",
"LABATECA, NORTE DE SANTANDER",
"LABRANZAGRANDE, BOYACÁ",
"LANDÁZURI, SANTANDER",
"LEBRÍJA, SANTANDER",
"LEIVA, NARIÑO",
"LEJANÍAS, META",
"LENGUAZAQUE, CUNDINAMARCA",
"LERIDA, TOLIMA",
"LETICIA, AMAZONAS",
"LIBANO, TOLIMA",
"LIBORINA, ANTIOQUIA",
"LINARES, NARIÑO",
"LLORÓ, CHOCO",
"LOPEZ, CAUCA",
"LORICA, CORDOBA",
"LOS ANDES, NARIÑO",
"LOS CÓRDOBAS, CORDOBA",
"LOS PALMITOS, SUCRE",
"LOS PATIOS, NORTE DE SANTANDER",
"LOS SANTOS, SANTANDER",
"LOURDES, NORTE DE SANTANDER",
"LURUACO, ATLÁNTICO",
"MACANAL, BOYACÁ",
"MACARAVITA, SANTANDER",
"MACEO, ANTIOQUIA",
"MACHETA, CUNDINAMARCA",
"MADRID, CUNDINAMARCA",
"MAGANGUÉ, BOLIVAR",
"MAGUI, NARIÑO",
"MAHATES, BOLIVAR",
"MAICAO, LA GUAJIRA",
"MAJAGUAL, SUCRE",
"MÁLAGA, SANTANDER",
"MALAMBO, ATLÁNTICO",
"MALLAMA, NARIÑO",
"MANATI, ATLÁNTICO",
"MANAURE, CESAR",
"MANAURE, LA GUAJIRA",
"MANÍ, CASANARE",
"MANIZALES, CALDAS",
"MANTA, CUNDINAMARCA",
"MANZANARES, CALDAS",
"MAPIRIPAN, GUAINIA",
"MAPIRIPAN, META",
"MARGARITA, BOLIVAR",
"MARÍA LA BAJA, BOLIVAR",
"MARINILLA, ANTIOQUIA",
"MARIPÍ, BOYACÁ",
"MARIQUITA, TOLIMA",
"MARMATO, CALDAS",
"MARQUETALIA, CALDAS",
"MARSELLA, RISARALDA",
"MARULANDA, CALDAS",
"MATANZA, SANTANDER",
"MEDELLÍN, ANTIOQUIA",
"MEDINA, CUNDINAMARCA",
"MEDIO ATRATO, CHOCO",
"MEDIO BAUDÓ, CHOCO",
"MEDIO SAN JUAN, CHOCO",
"MELGAR, TOLIMA",
"MERCADERES, CAUCA",
"MESETAS, META",
"MILAN, CAQUETA",
"MIRAFLORES, BOYACÁ",
"MIRAFLORES, GUAVIARE",
"MIRANDA, CAUCA",
"MIRITI - PARANÁ, AMAZONAS",
"MISTRATÓ, RISARALDA",
"MITÚ, VAUPES",
"MOCOA, PUTUMAYO",
"MOGOTES, SANTANDER",
"MOLAGAVITA, SANTANDER",
"MOMIL, CORDOBA",
"MOMPÓS, BOLIVAR",
"MONGUA, BOYACÁ",
"MONGUÍ, BOYACÁ",
"MONIQUIRÁ, BOYACÁ",
"MOÑITOS, CORDOBA",
"MONTEBELLO, ANTIOQUIA",
"MONTECRISTO, BOLIVAR",
"MONTELÍBANO, CORDOBA",
"MONTENGRO, QUINDIO",
"MONTERÍA, CORDOBA",
"MONTERREY, CASANARE",
"MORALES, CAUCA",
"MORALES, BOLIVAR",
"MORELIA, CAQUETA",
"MORICHAL, GUAINIA",
"MORROA, SUCRE",
"MOSQUERA, NARIÑO",
"MOSQUERA, CUNDINAMARCA",
"MOTAVITA, BOYACÁ",
"MURILLO, TOLIMA",
"MURINDÓ, ANTIOQUIA",
"MUTATA, ANTIOQUIA",
"MUTISCUA, NORTE DE SANTANDER",
"MUZO, BOYACÁ",
"NARIÑO, NARIÑO",
"NARIÑO, CUNDINAMARCA",
"NARIÑO, ANTIOQUIA",
"NÁTAGA, HUILA",
"NATAGAIMA, TOLIMA",
"NECHÍ, ANTIOQUIA",
"NECOCLÍ, ANTIOQUIA",
"NEIRA, CALDAS",
"NEIVA, HUILA",
"NEMOCON, CUNDINAMARCA",
"NILO, CUNDINAMARCA",
"NIMAIMA, CUNDINAMARCA",
"NOBSA, BOYACÁ",
"NOCAIMA, CUNDINAMARCA",
"NORCASIA, CALDAS",
"NÓVITA, CHOCO",
"NUEVA GRANADA, MAGDALENA",
"NUEVO COLÓN, BOYACÁ",
"NUNCHÍA, CASANARE",
"NUQUÍ, CHOCO",
"OBANDO, VALLE DEL CAUCA",
"OCAMONTE, SANTANDER",
"OCAÑA, NORTE DE SANTANDER",
"OIBA, SANTANDER",
"OICATÁ, BOYACÁ",
"OLAYA, ANTIOQUIA",
"OLAYA HERRERA, NARIÑO",
"ONZAGA, SANTANDER",
"OPORAPA, HUILA",
"ORITO, PUTUMAYO",
"OROCUÉ, CASANARE",
"ORTEGA, TOLIMA",
"OSPINA, NARIÑO",
"OTANCHE, BOYACÁ",
"OVEJAS, SUCRE",
"PACHAVITA, BOYACÁ",
"PACHO, CUNDINAMARCA",
"PACOA, VAUPES",
"PÁCORA, CALDAS",
"PADILLA, CAUCA",
"PÁEZ, BOYACÁ",
"PAEZ, CAUCA",
"PAICOL, HUILA",
"PAILITAS, CESAR",
"PAIME, CUNDINAMARCA",
"PAIPA, BOYACÁ",
"PAJARITO, BOYACÁ",
"PALERMO, HUILA",
"PALESTINA, HUILA",
"PALESTINA, CALDAS",
"PALMAR, SANTANDER",
"PALMAR DE VARELA, ATLÁNTICO",
"PALMAS DEL SOCORRO, SANTANDER",
"PALMIRA, VALLE DEL CAUCA",
"PALMITO, SUCRE",
"PALOCABILDO, TOLIMA",
"PAMPLONA, NORTE DE SANTANDER",
"PAMPLONITA, NORTE DE SANTANDER",
"PANA PANA, GUAINIA",
"PANDI, CUNDINAMARCA",
"PANQUEBA, BOYACÁ",
"PAPUNAHUA, VAUPES",
"PÁRAMO, SANTANDER",
"PARATEBUENO, CUNDINAMARCA",
"PASCA, CUNDINAMARCA",
"PASTO, NARIÑO",
"PATIA, CAUCA",
"PAUNA, BOYACÁ",
"PAYA, BOYACÁ",
"PAZ DE ARIPORO, CASANARE",
"PAZ DE RÍO, BOYACÁ",
"PEDRAZA, MAGDALENA",
"PELAYA, CESAR",
"PEÑOL, ANTIOQUIA",
"PENSILVANIA, CALDAS",
"PEQUE, ANTIOQUIA",
"PEREIRA, RISARALDA",
"PESCA, BOYACÁ",
"PIAMONTE, CAUCA",
"PIEDECUESTA, SANTANDER",
"PIEDRAS, TOLIMA",
"PIENDAMO, CAUCA",
"PIJAO, QUINDIO",
"PIJIÑO DEL CARMEN, MAGDALENA",
"PINCHOTE, SANTANDER",
"PINILLOS, BOLIVAR",
"PIOJÓ, ATLÁNTICO",
"PISBA, BOYACÁ",
"PITAL, HUILA",
"PITALITO, HUILA",
"PIVIJAY, MAGDALENA",
"PLANADAS, TOLIMA",
"PLANETA RICA, CORDOBA",
"PLATO, MAGDALENA",
"POLICARPA, NARIÑO",
"POLONUEVO, ATLÁNTICO",
"PONEDERA, ATLÁNTICO",
"POPAYÁN, CAUCA",
"PORE, CASANARE",
"POTOSÍ, NARIÑO",
"PRADERA, VALLE DEL CAUCA",
"PRADO, TOLIMA",
"PROVIDENCIA, NARIÑO",
"PROVIDENCIA Y SANTA CATALINA, ARCHIPIELAGO DE SAN ANDRES",
"PUEBLO BELLO, CESAR",
"PUEBLO NUEVO, CORDOBA",
"PUEBLO RICO, RISARALDA",
"PUEBLO VIEJO, MAGDALENA",
"PUEBLORRICO, ANTIOQUIA",
"PUENTE NACIONAL, SANTANDER",
"PUERRES, NARIÑO",
"PUERTO ALEGRIA, AMAZONAS",
"PUERTO ARICA, AMAZONAS",
"PUERTO ASIS, PUTUMAYO",
"PUERTO BERRIO, ANTIOQUIA",
"PUERTO BOYACA, BOYACÁ",
"PUERTO CAICEDO, PUTUMAYO",
"PUERTO CARREÑO, VICHADA",
"PUERTO COLOMBIA, GUAINIA",
"PUERTO COLOMBIA, ATLÁNTICO",
"PUERTO CONCORDIA, META",
"PUERTO ESCONDIDO, CORDOBA",
"PUERTO GAITÁN, META",
"PUERTO GUZMAN, PUTUMAYO",
"PUERTO LEGUIZAMO, PUTUMAYO",
"PUERTO LIBERTADOR, CORDOBA",
"PUERTO LLERAS, META",
"PUERTO LOPEZ, META",
"PUERTO NARE, ANTIOQUIA",
"PUERTO NARIÑO, AMAZONAS",
"PUERTO PARRA, SANTANDER",
"PUERTO RICO, CAQUETA",
"PUERTO RICO, META",
"PUERTO RONDÓN, ARAUCA",
"PUERTO SALGAR, CUNDINAMARCA",
"PUERTO SANTANDER, AMAZONAS",
"PUERTO SANTANDER, NORTE DE SANTANDER",
"PUERTO TEJADA, CAUCA",
"PUERTO TRIUNFO, ANTIOQUIA",
"PUERTO WILCHES, SANTANDER",
"PULI, CUNDINAMARCA",
"PUPIALES, NARIÑO",
"PURACE, CAUCA",
"PURIFICACIÓN, TOLIMA",
"PURÍSIMA, CORDOBA",
"QUEBRADANEGRA, CUNDINAMARCA",
"QUETAME, CUNDINAMARCA",
"QUIBDÓ, CHOCO",
"QUIMBAYA, QUINDIO",
"QUINCHIA, RISARALDA",
"QUÍPAMA, BOYACÁ",
"QUIPILE, CUNDINAMARCA",
"RAGONVALIA, NORTE DE SANTANDER",
"RAMIRIQUÍ, BOYACÁ",
"RÁQUIRA, BOYACÁ",
"RECETOR, CASANARE",
"REGIDOR, BOLIVAR",
"REMEDIOS, ANTIOQUIA",
"REMOLINO, MAGDALENA",
"REPELON, ATLÁNTICO",
"RESTREPO, META",
"RESTREPO, VALLE DEL CAUCA",
"RETIRO, ANTIOQUIA",
"RICAURTE, NARIÑO",
"RICAURTE, CUNDINAMARCA",
"RÍO DE ORO, CESAR",
"RÍO FRÍO, CHOCO",
"RIO QUITO, CHOCO",
"RÍO VIEJO, BOLIVAR",
"RIOBLANCO, TOLIMA",
"RIOFRIO, VALLE DEL CAUCA",
"RIOHACHA, LA GUAJIRA",
"RIONEGRO, ANTIOQUIA",
"RIONEGRO, SANTANDER",
"RIOSUCIO, CHOCO",
"RIOSUCIO, CALDAS",
"RISARALDA, CALDAS",
"RIVERA, HUILA",
"ROBERTO PAYAN, NARIÑO",
"ROLDANILLO, VALLE DEL CAUCA",
"RONCESVALLES, TOLIMA",
"RONDÓN, BOYACÁ",
"ROSAS, CAUCA",
"ROVIRA, TOLIMA",
"SABANA DE TORRES, SANTANDER",
"SABANAGRANDE, ATLÁNTICO",
"SABANALARGA, ANTIOQUIA",
"SABANALARGA, ATLÁNTICO",
"SABANALARGA, CASANARE",
"SABANAS DE SAN ANGEL, MAGDALENA",
"SABANETA, ANTIOQUIA",
"SABOYÁ, BOYACÁ",
"SÁCAMA, CASANARE",
"SÁCHICA, BOYACÁ",
"SAHAGÚN, CORDOBA",
"SALADOBLANCO, HUILA",
"SALAMINA, MAGDALENA",
"SALAMINA, CALDAS",
"SALAZAR, NORTE DE SANTANDER",
"SALDAÑA, TOLIMA",
"SALENTO, QUINDIO",
"SALGAR, ANTIOQUIA",
"SAMACÁ, BOYACÁ",
"SAMANÁ, CALDAS",
"SAMANIEGO, NARIÑO",
"SAMPUÉS, SUCRE",
"SAN AGUSTÍN, HUILA",
"SAN ALBERTO, CESAR",
"SAN ANDRES, ARCHIPIELAGO DE SAN ANDRES",
"SAN ANDRÉS, SANTANDER",
"SAN ANDRÉS, ANTIOQUIA",
"SAN ANDRÉS SOTAVENTO, CORDOBA",
"SAN ANTERO, CORDOBA",
"SAN ANTONIO, TOLIMA",
"SAN ANTONIO DE TEQUENDAMA, CUNDINAMARCA",
"SAN BENITO, SANTANDER",
"SAN BENITO ABAD, SUCRE",
"SAN BERNARDO, NARIÑO",
"SAN BERNARDO, CUNDINAMARCA",
"SAN BERNARDO DEL VIENTO, CORDOBA",
"SAN CALIXTO, NORTE DE SANTANDER",
"SAN CARLOS, ANTIOQUIA",
"SAN CARLOS, CORDOBA",
"SAN CARLOS GUAROA, META",
"SAN CAYETANO, CUNDINAMARCA",
"SAN CAYETANO, NORTE DE SANTANDER",
"SAN CRISTOBAL, BOLIVAR",
"SAN DIEGO, CESAR",
"SAN EDUARDO, BOYACÁ",
"SAN ESTANISLAO, BOLIVAR",
"SAN FELIPE, GUAINIA",
"SAN FERNANDO, BOLIVAR",
"SAN FRANCISCO, ANTIOQUIA",
"SAN FRANCISCO, PUTUMAYO",
"SAN FRANCISCO, CUNDINAMARCA",
"SAN GIL, SANTANDER",
"SAN JACINTO, BOLIVAR",
"SAN JACINTO DEL CAUCA, BOLIVAR",
"SAN JERÓNIMO, ANTIOQUIA",
"SAN JOAQUÍN, SANTANDER",
"SAN JOSÉ, CALDAS",
"SAN JOSÉ DE LA MONTAÑA, ANTIOQUIA",
"SAN JOSÉ DE MIRANDA, SANTANDER",
"SAN JOSÉ DE PARE, BOYACÁ",
"SAN JOSE DEL FRAGUA, CAQUETA",
"SAN JOSÉ DEL GUAVIARE, GUAVIARE",
"SAN JOSÉ DEL PALMAR, CHOCO",
"SAN JUAN BETULIA, SUCRE",
"SAN JUAN DE ARAMA, META",
"SAN JUAN DE RÍO SECO, CUNDINAMARCA",
"SAN JUAN DE URABA, ANTIOQUIA",
"SAN JUAN DEL CESAR, LA GUAJIRA",
"SAN JUAN NEPOMUCENO, BOLIVAR",
"SAN JUANITO, META",
"SAN LORENZO, NARIÑO",
"SAN LUIS, ANTIOQUIA",
"SAN LUIS, TOLIMA",
"SAN LUIS DE CUBARRAL, META",
"SAN LUIS DE GACENO, BOYACÁ",
"SAN LUIS DE PALENQUE, CASANARE",
"SAN MARCOS, SUCRE",
"SAN MARTÍN, CESAR",
"SAN MARTÍN, META",
"SAN MARTIN DE LOBA, BOLIVAR",
"SAN MATEO, BOYACÁ",
"SAN MIGUEL, PUTUMAYO",
"SAN MIGUEL, SANTANDER",
"SAN MIGUEL DE SEMA, BOYACÁ",
"SAN ONOFRE, SUCRE",
"SAN PABLO, NARIÑO",
"SAN PABLO, BOLIVAR",
"SAN PABLO BORBUR, BOYACÁ",
"SAN PEDRO, ANTIOQUIA",
"SAN PEDRO, SUCRE",
"SAN PEDRO, VALLE DEL CAUCA",
"SAN PEDRO DE CARTAGO, NARIÑO",
"SAN PEDRO DE URABA, ANTIOQUIA",
"SAN PELAYO, CORDOBA",
"SAN RAFAEL, ANTIOQUIA",
"SAN ROQUE, ANTIOQUIA",
"SAN ROSA VITERBO, BOYACÁ",
"SAN SEBASTIAN, CAUCA",
"SAN SEBASTIAN DE BUENAVISTA, MAGDALENA",
"SAN VICENTE, ANTIOQUIA",
"SAN VICENTE DE CHUCURÍ, SANTANDER",
"SAN VICENTE DEL CAGUÁN, CAQUETA",
"SAN ZENON, MAGDALENA",
"SANDONÁ, NARIÑO",
"SANTA ANA, MAGDALENA",
"SANTA BARBARA, ANTIOQUIA",
"SANTA BARBARA, NARIÑO",
"SANTA BÁRBARA, SANTANDER",
"SANTA BARBARA DE PINTO, MAGDALENA",
"SANTA CATALINA, BOLIVAR",
"SANTA CRUZ, NARIÑO",
"SANTA HELENA DEL OPÓN, SANTANDER",
"SANTA ISABEL, TOLIMA",
"SANTA LUCIA, ATLÁNTICO",
"SANTA MARÍA, BOYACÁ",
"SANTA MARÍA, HUILA",
"SANTA MARTA, MAGDALENA",
"SANTA ROSA, CAUCA",
"SANTA ROSA DE CABAL, RISARALDA",
"SANTA ROSA DE LIMA, BOLIVAR",
"SANTA ROSA DE OSOS, ANTIOQUIA",
"SANTA ROSA DEL SUR, BOLIVAR",
"SANTA ROSALÍA, VICHADA",
"SANTA SOFÍA, BOYACÁ",
"SANTAFÉ DE ANTIOQUIA, ANTIOQUIA",
"SANTANA, BOYACÁ",
"SANTANDER DE QUILICHAO, CAUCA",
"SANTIAGO, NORTE DE SANTANDER",
"SANTIAGO, PUTUMAYO",
"SANTIAGO DE TOLÚ, SUCRE",
"SANTO DOMINGO, ANTIOQUIA",
"SANTO TOMAS, ATLÁNTICO",
"SANTUARIO, ANTIOQUIA",
"SANTUARIO, RISARALDA",
"SAPUYES, NARIÑO",
"SARAVENA, ARAUCA",
"SARDINATA, NORTE DE SANTANDER",
"SASAIMA, CUNDINAMARCA",
"SATIVANORTE, BOYACÁ",
"SATIVASUR, BOYACÁ",
"SEGOVIA, ANTIOQUIA",
"SESQUILÉ, CUNDINAMARCA",
"SEVILLA, VALLE DEL CAUCA",
"SIACHOQUE, BOYACÁ",
"SIBATÉ, CUNDINAMARCA",
"SIBUNDOY, PUTUMAYO",
"SILOS, NORTE DE SANTANDER",
"SILVANIA, CUNDINAMARCA",
"SILVIA, CAUCA",
"SIMACOTA, SANTANDER",
"SIMIJACA, CUNDINAMARCA",
"SIMITÍ, BOLIVAR",
"SINCÉ, SUCRE",
"SINCELEJO, SUCRE",
"SIPÍ, CHOCO",
"SITIONUEVO, MAGDALENA",
"SOACHA, CUNDINAMARCA",
"SOATÁ, BOYACÁ",
"SOCHA, BOYACÁ",
"SOCORRO, SANTANDER",
"SOCOTÁ, BOYACÁ",
"SOGAMOSO, BOYACÁ",
"SOLANO, CAQUETA",
"SOLEDAD, ATLÁNTICO",
"SOLITA, CAQUETA",
"SOMONDOCO, BOYACÁ",
"SONSON, ANTIOQUIA",
"SOPETRAN, ANTIOQUIA",
"SOPLAVIENTO, BOLIVAR",
"SOPÓ, CUNDINAMARCA",
"SORA, BOYACÁ",
"SORACÁ, BOYACÁ",
"SOTAQUIRÁ, BOYACÁ",
"SOTARA, CAUCA",
"SUAITA, SANTANDER",
"SUAN, ATLÁNTICO",
"SUAREZ, CAUCA",
"SUÁREZ, TOLIMA",
"SUAZA, HUILA",
"SUBACHOQUE, CUNDINAMARCA",
"SUCRE, SANTANDER",
"SUCRE, SUCRE",
"SUCRE, CAUCA",
"SUESCA, CUNDINAMARCA",
"SUPATÁ, CUNDINAMARCA",
"SUPÍA, CALDAS",
"SURATA, SANTANDER",
"SUSA, CUNDINAMARCA",
"SUSACÓN, BOYACÁ",
"SUTAMARCHÁN, BOYACÁ",
"SUTATAUSA, CUNDINAMARCA",
"SUTATENZA, BOYACÁ",
"TABIO, CUNDINAMARCA",
"TADÓ, CHOCO",
"TALAIGUA NUEVO, BOLIVAR",
"TAMALAMEQUE, CESAR",
"TÁMARA, CASANARE",
"TAME, ARAUCA",
"TÁMESIS, ANTIOQUIA",
"TAMINANGO, NARIÑO",
"TANGUA, NARIÑO",
"TARAIRA, VAUPES",
"TARAPACÁ, AMAZONAS",
"TARAZÁ, ANTIOQUIA",
"TARQUI, HUILA",
"TARSO, ANTIOQUIA",
"TASCO, BOYACÁ",
"TAURAMENA, CASANARE",
"TAUSA, CUNDINAMARCA",
"TELLO, HUILA",
"TENA, CUNDINAMARCA",
"TENERIFE, MAGDALENA",
"TENJO, CUNDINAMARCA",
"TENZA, BOYACÁ",
"TEORAMA, NORTE DE SANTANDER",
"TERUEL, HUILA",
"TESALIA, HUILA",
"TIBACUY, CUNDINAMARCA",
"TIBANÁ, BOYACÁ",
"TIBASOSA, BOYACÁ",
"TIBIRITA, CUNDINAMARCA",
"TIBÚ, NORTE DE SANTANDER",
"TIERRALTA, CORDOBA",
"TIMANÁ, HUILA",
"TIMBIO, CAUCA",
"TIMBIQUI, CAUCA",
"TINJACÁ, BOYACÁ",
"TIPACOQUE, BOYACÁ",
"TIQUISIO, BOLIVAR",
"TITIRIBÍ, ANTIOQUIA",
"TOCA, BOYACÁ",
"TOCAIMA, CUNDINAMARCA",
"TOCANCIPÁ, CUNDINAMARCA",
"TOGÜÍ, BOYACÁ",
"TOLEDO, ANTIOQUIA",
"TOLEDO, NORTE DE SANTANDER",
"TOLÚ VIEJO, SUCRE",
"TONA, SANTANDER",
"TÓPAGA, BOYACÁ",
"TOPAIPI, CUNDINAMARCA",
"TORIBIO, CAUCA",
"TORO, VALLE DEL CAUCA",
"TOTA, BOYACÁ",
"TOTORO, CAUCA",
"TRINIDAD, CASANARE",
"TRUJILLO, VALLE DEL CAUCA",
"TUBARA, ATLÁNTICO",
"TULUÁ, VALLE DEL CAUCA",
"TUMACO, NARIÑO",
"TUNJA, BOYACÁ",
"TUNUNGUÁ, BOYACÁ",
"TUQUERRES, NARIÑO",
"TURBACO, BOLIVAR",
"TURBANA, BOLIVAR",
"TURBO, ANTIOQUIA",
"TURMEQUÉ, BOYACÁ",
"TUTA, BOYACÁ",
"TUTAZÁ, BOYACÁ",
"UBALÁ, CUNDINAMARCA",
"UBAQUE, CUNDINAMARCA",
"UBATE, CUNDINAMARCA",
"ULLOA, VALLE DEL CAUCA",
"UMBITA, BOYACÁ",
"UNE, CUNDINAMARCA",
"UNGUÍA, CHOCO",
"UNION PANAMERICANA, CHOCO",
"URAMITA, ANTIOQUIA",
"URIBIA, LA GUAJIRA",
"URRAO, ANTIOQUIA",
"URUMITA, LA GUAJIRA",
"USIACURI, ATLÁNTICO",
"ÚTICA, CUNDINAMARCA",
"VALDIVIA, ANTIOQUIA",
"VALENCIA, CORDOBA",
"VALLE DE SAN JOSÉ, SANTANDER",
"VALLE DE SAN JUAN, TOLIMA",
"VALLE DEL GUAMUEZ, PUTUMAYO",
"VALLEDUPAR, CESAR",
"VALPARAISO, CAQUETA",
"VALPARAISO, ANTIOQUIA",
"VEGACHÍ, ANTIOQUIA",
"VÉLEZ, SANTANDER",
"VENADILLO, TOLIMA",
"VENECIA, ANTIOQUIA",
"VENECIA, CUNDINAMARCA",
"VENTAQUEMADA, BOYACÁ",
"VERGARA, CUNDINAMARCA",
"VERSALLES, VALLE DEL CAUCA",
"VETAS, SANTANDER",
"VIANÍ, CUNDINAMARCA",
"VICTORIA, CALDAS",
"VIGÍA DEL FUERTE, ANTIOQUIA",
"VIJES, VALLE DEL CAUCA",
"VILLA CARO, NORTE DE SANTANDER",
"VILLA DE LEYVA, BOYACÁ",
"VILLA DEL ROSARIO, NORTE DE SANTANDER",
"VILLA GARZON, PUTUMAYO",
"VILLA RICA, CAUCA",
"VILLAGOMEZ, CUNDINAMARCA",
"VILLAHERMOSA, TOLIMA",
"VILLAMARIA, CALDAS",
"VILLANUEVA, CASANARE",
"VILLANUEVA, BOLIVAR",
"VILLANUEVA, SANTANDER",
"VILLANUEVA, LA GUAJIRA",
"VILLAPINZÓN, CUNDINAMARCA",
"VILLARRICA, TOLIMA",
"VILLAVICENCIO, META",
"VILLAVIEJA, HUILA",
"VILLETA, CUNDINAMARCA",
"VIOTÁ, CUNDINAMARCA",
"VIRACACHÁ, BOYACÁ",
"VISTA HERMOSA, META",
"VITERBO, CALDAS",
"YACOPÍ, CUNDINAMARCA",
"YACUANQUER, NARIÑO",
"YAGUARÁ, HUILA",
"YALÍ, ANTIOQUIA",
"YARUMAL, ANTIOQUIA",
"YAVARATÉ, VAUPES",
"YOLOMBÓ, ANTIOQUIA",
"YONDÓ, ANTIOQUIA",
"YOPAL, CASANARE",
"YOTOCO, VALLE DEL CAUCA",
"YUMBO, VALLE DEL CAUCA",
"ZAMBRANO, BOLIVAR",
"ZAPATOCA, SANTANDER",
"ZAPAYAN, MAGDALENA",
"ZARAGOZA, ANTIOQUIA",
"ZARZAL, VALLE DEL CAUCA",
"ZETAQUIRA, BOYACÁ",
"ZIPACON, CUNDINAMARCA",
"ZIPAQUIRÁ, CUNDINAMARCA",
"ZONA BANANERA, MAGDALENA",
]