import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Table } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

export default function SurveysTable({ surveys, reload, pagination, setPagination }) {
	const [responses, setResponses] = useState(null)

	const columns = [
		{
			title: 'Puntaje',
			dataIndex: 'score'
		},
		{
			title: 'Cliente',
			dataIndex: 'delivery_customer',
			render: t => t.name
		},
		{
			title: 'Número de Remisión',
			dataIndex: 'delivery_order',
			render: t => `#${t.code}`
		},
		{
			title: 'Fecha de Encuesta',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD HH:mm')
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={surveys}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				expandable={{
					expandedRowRender: record => {
						for (const [key, value] of Object.entries(record.responses)) {
							return <span>{`${key}: ${value}`}</span>
						}
					},
				}}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<Modal visible={responses ? true : false} toggle={()=>setResponses(null)}>
				<ModalHeader toggle={()=>setResponses(null)}>Respuestas de Encuesta</ModalHeader>
				<ModalBody>
					
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}