import springApi from '../../services/springApi'

export const getProductPromises = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/product_promises', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeProductPromise = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/product_promises', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateProductPromise = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/product_promises/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteProductPromise = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/product_promises/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)