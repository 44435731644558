import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import CityRoutesList from './CityRoutesList'

export default function CityRoutes() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/city_routes" component={CityRoutesList} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}