import springApi from '../../services/springApi'

export const getDeliveries = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/deliveries', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeDelivery = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/deliveries', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateDelivery = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/deliveries/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)