import springApi from '../../services/springApi'

export const getBannedDays = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/banned_days', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeBannedDay = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/banned_days', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateBannedDay = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/banned_days/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteBannedDay = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/banned_days/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)