import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Spinner } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message } from 'antd'

import AuthLayout from '../../layouts/AuthLayout'
import { searchCustomer, signWithoutPin } from './services'
import { handleError, ucwords } from '../../helpers'

export default function NewSignDelivery() {
	const { handleSubmit, register, reset, errors, watch, setValue } = useForm()
	const [loadingSearch, setLoadingSearch] = useState(false)

	const onSubmit = values => {
		signWithoutPin(values)
			.then(res => {
				message.success(res.data.message)
				reset()
			})
			.catch(error => handleError(error))
	}

	let userDocument = watch('document')

	const handleSearch = () => {
		setLoadingSearch(true)
		searchCustomer({ document: userDocument })
			.then(res => {
				setLoadingSearch(false)

				if(!res.data.data){
					setValue('name', '')
					setValue('email', '')
					setValue('mobile', '')
					return message.warn('No se encontraron datos para autocompletar')
				}

				setValue('name', ucwords(res.data.data.name))
				setValue('email', res.data.data.email)
				setValue('mobile', res.data.data.mobile ? parseInt(res.data.data.mobile) : parseInt(res.data.data.phone))

				message.info('Datos autocompletados exitosamente')
			})
			.catch(error => {
				handleError(error)
				setLoadingSearch(false)
			})
	}

	return (
		<AuthLayout>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<h6>REGISTRAR ENTREGA SPRING</h6>
				<div className='text-left mt-4'>
					<Form.Group>
						<Form.Label>Cédula Transportador <span className='text-primary'>*</span></Form.Label>
						<Form.Control 
							type="number" 
							name="user_document"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.document && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Número de Remisión <span className='text-primary'>*</span></Form.Label>
						<Form.Control 
							type="number" 
							name="order_code"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.order_code && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Cédula del Cliente <span className='text-primary'>*</span></Form.Label>
						<InputGroup>
							<Form.Control 
								type="number" 
								name="document"
								ref={register({ required:true })}
								placeholder="Escriba aquí..."
							/>
								<Button color="primary" outline onClick={handleSearch} disabled={loadingSearch}>
									{ !loadingSearch ? 'Consultar Datos' : <Spinner size="sm" /> }
								</Button>
							</InputGroup>
					</Form.Group>
					{ errors.document && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					<Form.Group>
						<Form.Label>Nombre del Cliente <span className='text-primary'>*</span></Form.Label>
						<Form.Control 
							name="name"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Teléfono Celular del Cliente <span className='text-primary'>*</span></Form.Label>
						<Form.Control 
							type="number"
							name="mobile"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.mobile && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Correo Electrónico del Cliente <small>(opcional)</small></Form.Label>
						<Form.Control 
							type="email" 
							name="email"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
						/>
					</Form.Group>
				</div>
				<Button color="primary" className="full-width">Registrar Entrega</Button>
			</Form>
		</AuthLayout>
	)
}