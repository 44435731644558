import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeUser } from '../services'
import { handleError } from '../../../helpers'

export default function NewUserForm({ reload }) {
	const { handleSubmit, errors, register, reset } = useForm()

	const onSubmit = values => {
		values.password = values.document.slice(values.document.length - 4)
		values.password_confirmation = values.password
		storeUser(values)
			.then(() => {
				message.success('Usuario creado exitosamente.')
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
					<Form.Control name="name" ref={register({ required: true })} />
					{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Documento de Identidad <span className="text-danger">*</span></Form.Label>
					<Form.Control name="document" ref={register({ required: true })} />
					{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Correo Electrónico (Opcional)</Form.Label>
					<Form.Control name="email" ref={register({})} />
				</Form.Group>
				<Form.Group>
					<Form.Label>Telefono Celular <span className="text-danger">*</span></Form.Label>
					<Form.Control name="mobile" ref={register({ required:true })} />
					{ errors.mobile && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Rol de Usuario</Form.Label>
					<Form.Control as="select" name="role" ref={register({})} defaultValue="agent">
						<option value="agent">Transportador</option>
						<option value="admin">Administrador</option>
					</Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>Contraseña</Form.Label>
					<Form.Control name="password" ref={register({})} placeholder="Últimos 4 dígitos de la cédula" disabled />
				</Form.Group>
				<Button type="submit" color="primary">Crear Usuario</Button>
			</Form>
		</React.Fragment>
	)
}