import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import NewUserForm from './partials/NewUserForm'
import UsersTable from './partials/UsersTable'
import { getUsers } from './services'
import { handleError } from '../../helpers'

export default function UsersList() {
	const [users, setUsers] = useState(null)

	useEffect(()=>{
		!users && getUsers()
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Usuario Nuevo</CardTitle>
							<CardSubtitle>Ingrese los datos de nuevo usuario</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewUserForm reload={()=>setUsers(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Usuarios</CardTitle>
							<CardSubtitle>Administre los usuarios con acceso a la plataforma</CardSubtitle>
						</CardHeader>
						<CardBody>
							<UsersTable users={users} reload={()=>setUsers(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}