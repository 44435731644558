import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import OrdersList from './OrdersList'

export default function Orders() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/orders" component={OrdersList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}