import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Authentication from './containers/Authentication/'
import BannedCountDays from "./containers/BannedCountDays"
import BannedDays from "./containers/BannedDays"
import CityRoutes from "./containers/CityRoutes"
import Deliveries from "./containers/Deliveries"
import Error404 from './containers/ErrorsPages/Error404'
import Orders from "./containers/Orders"
import ProductPromises from "./containers/ProductPromises"
import SignDelivery from "./containers/SignDelivery"
import Surveys from "./containers/Surveys"
import NewSurvey from "./containers/Surveys/NewSurvey"
import Tickets from "./containers/Tickets"
import Users from "./containers/Users"

import MainLayout from "./layouts/MainLayout"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/deliveries" />

				<Route exact path="/encuesta/:deliveryId" component={NewSurvey} />
				<Route exact path="/firmar" component={SignDelivery} />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/orders" component={Orders} />
					<Route path="/deliveries" component={Deliveries} />
					<Route path="/tickets" component={Tickets} />
					<Route path="/surveys" component={Surveys} />
					<Route path="/users" component={Users} />
					<Route path="/banned_days" component={BannedDays} />
					<Route path="/banned_count_days" component={BannedCountDays} />
					<Route path="/product_promises" component={ProductPromises} />
					<Route path="/city_routes" component={CityRoutes} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}