import springApi from '../../services/springApi'

export const signWithoutPin = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/public/sign/withoutpin', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCustomer = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/public/customers/search', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)