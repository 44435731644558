import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle, Alert } from 'reactstrap'

import BannedCountDaysTable from './partials/BannedCountDaysTable'
import NewBannedCountDay from './partials/NewBannedCountDay'
import { getBannedCountDays } from './services'
import { handleError } from '../../helpers'

export default function BannedCountDaysList() {
	const [bannedCountDays, setBannedCountDays] = useState(null)

	useEffect(() => {
		!bannedCountDays && getBannedCountDays({
			sort: '-date'
		})
			.then(res => setBannedCountDays(res.data.data))
			.catch(error => handleError(error))
	}, [ bannedCountDays ])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Fecha No Hábil</CardTitle>
							<CardSubtitle>Fechas no hábiles para conteo</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewBannedCountDay reload={()=>setBannedCountDays(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="7">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Fechas No Hábiles para Conteo </CardTitle>
							<CardSubtitle>Administre las fechas no hábiles para conteo</CardSubtitle>
						</CardHeader>
						<CardBody>
							<Alert color="warning">Por defecto no se tiene en cuenta los domingos</Alert>
							<BannedCountDaysTable
								bannedCountDays={bannedCountDays} 
								reload={()=>setBannedCountDays(null)} 
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>	
		</React.Fragment>
	)
}