import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

export default function TicketsTable({ tickets, reload, pagination, setPagination }) {

	const columns = [
		{
			title: 'Motivo',
			dataIndex: 'reason'
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => `#${t.name}`
		},
		{
			title: 'Número de Remisión',
			dataIndex: 'order',
			render: t => `#${t.code}`
		},
		{
			title: 'Fecha Remisión',
			dataIndex: 'order',
			render: t => moment(t.ordered_at).format('YYYY-MM-DD HH:mm')
		},
		{
			title: 'Fecha Novedad',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD HH:mm')
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={tickets}
				rowKey={record => record.id}
				columns={columns}
				expandable={{
					expandedRowRender: record => <p style={{ margin: 0 }}><span className="bold">Comentario: </span>{record.comment}</p>,
				}}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}