import springApi from '../../services/springApi'

export const getUsers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/users', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeUser = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/users', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateUser = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/users/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteUser = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/users/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)