import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'

export default function SignModal({ visible, onClose, deliveryId, reload }) {
	const { handleSubmit, errors, register } = useForm()

	const onSubmit = values => {}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Firmar Entrega</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Pin de Confirmación</Form.Label>
							<Form.Control 
								name="token"
								ref={register({ required:true })}
							/>
							{ errors.token && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
					</Form>
					<Button color="primary">Firmar Entrega</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}