import React from 'react'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2'

export default function SurveysReport({ stats }) {

	const data = stats ? {
		labels: ['Insatisfecho', 'Malo', 'Normal', 'Bueno', 'Excelente'],
		datasets: [
		  {
			 label: '# cotizaciones',
			 data: [
				stats[1],
				stats[2],
				stats[3],
				stats[4],
				stats[5],
			],
			backgroundColor: [
				 'rgba(255, 0, 0, 0.6)',
				 'rgba(255, 206, 86, 0.8)',
				 'rgba(54, 162, 235, 0.6)',
				 'rgba(75, 192, 192, 0.4)',
				 'rgba(75, 192, 192, 0.8)',

			],
			 borderColor: [
				'rgba(255, 0, 0, 0.6)',
				'rgba(255, 206, 86, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(75, 192, 192, 0.5)',
				'rgba(75, 192, 192, 1)',
			 ],
			 borderWidth: 1,
		  },
		],
	} : {}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					{ stats ? <Doughnut data={data} /> : <Spinner size="sm" />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}