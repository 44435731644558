import React from 'react'
import { Table, Popconfirm, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import { deleteBannedDay } from '../services'
import { handleError } from '../../../helpers'

export default function BannedDaysTable({ bannedDays, reload }) {
	
	const columns = [
		{
			title: 'Fecha no hábil',
			dataIndex: 'date',
			render: t => moment(t).format('dddd DD MMMM YYYY').toUpperCase()
		},
		{
			title: 'Descripción',
			dataIndex: 'description'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea eliminar la fecha?"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger: true }}
							onConfirm={() => handleDelete(record.id)}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteBannedDay(id)
			.then(() => {
				message.success('Fecha eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={bannedDays}
				columns={columns}
				rowKey={record => record.id}
				size="middle"
				loading={!bannedDays}
			/>
		</React.Fragment>
	)
}