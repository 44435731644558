import React, { useEffect, useState } from 'react'
import {Card, CardBody, CardHeader, CardTitle, CardSubtitle, Button} from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'

import TicketsTable from './partials/TicketsTable'
import { handleError } from '../../helpers'
import { getTickets } from './services'

export default function TicketsList() {
	const [tickets, setTickets] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterType, setFilterType] = useState('order.code')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!tickets && getTickets({ 
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'order,user' 
		})
			.then(res => setTickets(res.data.data))
			.catch(error => handleError(error))
	}, [tickets, pagination, filterType, filterValue])

	return (
		<React.Fragment>
			<Card>
				<CardHeader>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									<option value="order.code">Buscar por número de remisión</option>
									<option value="reason">Buscar por motivo de novedad</option>
									<option value="comment">Buscar por comentario en novedad</option>
									<option value="user.name">Buscar por nombre del transportador</option>
									<option value="user.document">Buscar por cédula del transportador</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={filterValue}
								onChange={e => setFilterValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setTickets(null)}>Buscar</Button>
							</InputGroup.Append>
						</InputGroup>
					</div>
					<CardTitle>Novedades de Entregas</CardTitle>
					<CardSubtitle>Listado de novedades registradas en el sistema</CardSubtitle>
				</CardHeader>
				<CardBody>
					<TicketsTable 
						tickets={tickets} 
						reload={()=>setTickets(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}