import React, { useState } from 'react'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMoneyBillAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditCityRouteModal from './EditCityRouteModal'
import CityRouteRatesModal from './CityRouteRatesModal'
import { deleteCityRoute } from '../services'
import { handleError } from '../../../helpers'

export default function CityRoutesTable({ cityRoutes, reload }) {
	const [editCityRoute, setEditCityRoute] = useState(null)
	const [routeRates, setRouteRates] = useState(null)

	const columns = [
		{
			title: 'Ciudad',
			dataIndex: 'city',
		},
		{
			title: 'Regional',
			dataIndex: 'region'
		},
		{
			title: 'Frecuencia',
			dataIndex: 'frecuency'
		},
		{
			title: 'Código Ruta',
			dataIndex: 'code',
		},
		{
			title: 'Pago Contra Entrega',
			dataIndex: 'pay_on_delivery',
			render: t => t ? 'Si' : 'No'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link"
							icon={faEdit}
							onClick={() => setEditCityRoute(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Tarifas">
						<FontAwesomeIcon 
							className="text-link"
							icon={faMoneyBillAlt}
							onClick={() => setRouteRates(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea eliminarlo?"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={() => handleDelete(record.id)}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteCityRoute(id)
			.then(() => {
				message.success('Eliminar ruta de entrega')
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={cityRoutes}
				columns={columns}
				rowKey={record => record.id}
				loading={!cityRoutes}
				size="middle"
				expandable={{ 
					expandedRowRender: record => (
						<React.Fragment>
							<p className="mb-1"><strong>Descripción: </strong>{record.description ? record.description : 'Sin registro'}</p>
							<p className="mb-1"><strong>Observación: </strong>{record.comment ? record.comment : 'Sin registro'}</p>
							<p className="mb-1"><strong>Observación de Frecuencia: </strong>{record.frecuency_comment ? record.frecuency_comment : 'Sin registro'}</p>
							<p className="mb-1"><strong>Origin: </strong>{record.origin ? record.origin : 'Sin registro'}</p>
							<p className="mb-1"><strong>Parada previa en regional: </strong>{record.regional_stop} días</p>
						</React.Fragment>
					)
				}}
			/>
			<EditCityRouteModal 
				visible={editCityRoute ? true : false}
				onClose={() => setEditCityRoute(null)}
				cityRoute={editCityRoute}
				reload={reload}
			/>
			<CityRouteRatesModal 
				visible={routeRates ? true : false}
				onClose={() => setRouteRates(null)}
				cityRoute={routeRates}
				reload={reload}
			/>
		</React.Fragment>
	)
}