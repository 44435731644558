import springApi from '../../services/springApi'

export const getCityRoutes = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/city_routes', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCityRoute = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/city_routes', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCityRoute = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/city_routes/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCityRoute = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/city_routes/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCityRouteRate = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/city_route_rates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCityRouteRate = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/city_route_rates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCityRouteRate = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/city_route_rates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

