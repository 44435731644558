import React from 'react'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { message } from 'antd'

import CityRouteForm from './CityRouteForm'
import { storeCityRoute } from '../services'
import { handleError } from '../../../helpers'

export default function NewCityRouteModal({ visible, onClose, reload }) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		storeCityRoute(values)
			.then(() => {
				message.success('Ruta agregada exitosamente')
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Nueva Ruta de Entrega</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<CityRouteForm register={register} errors={errors} />
						<Button color="primary" type="submit">Agregar Ruta de Entrega</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}