import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import NewSignDelivery from './NewSignDelivery'

export default function SignDelivery() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/firmar" component={NewSignDelivery} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}