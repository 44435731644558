import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import { handleError } from '../../helpers'
import CityRoutesTable from './partials/CityRoutesTable'
import NewCityRouteModal from './partials/NewCityRouteModal'
import { getCityRoutes } from './services'

export default function CityRoutesList() {
	const [cityRoutes, setCityRoutes] = useState(null)
	const [filterType, setFilterType] = useState('city')
	const [filterValue, setFilterValue] = useState('')
	const [showCreateModal, setShowCreateModal] = useState(false)

	useEffect(() => {
		!cityRoutes && getCityRoutes({
			[`filter[${filterType}]`]: filterValue,
			sort: 'city',
			include: 'cityRouteRates'
		})
			.then(res => setCityRoutes(res.data.data))
			.catch(error => handleError(error))
	}, [ cityRoutes, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Listado de Rutas de Entrega"
				subtitle="Detalle de rutas de entrega por ciudad"
				reload={() => setCityRoutes(null)}
				options={[
					{ value: 'city', label: 'Buscar por ciudad' },
					{ value: 'region', label: 'Buscar por regional' },
					{ value: 'code', label: 'Buscar por código de ruta' },
				]}
				ctaText="Agregar Ruta"
				handleCtaClick={() => setShowCreateModal(true)}
			/>
			<Card>
				<CardBody>
					<CityRoutesTable 
						cityRoutes={cityRoutes}
						reload={() => setCityRoutes(null)}
					/>
				</CardBody>
			</Card>

			<NewCityRouteModal 
				visible={showCreateModal}
				onClose={() => setShowCreateModal(false)}
				reload={() => setCityRoutes(null)}
			/>
		</React.Fragment>
	)
}