import springApi from '../../services/springApi'

export const getBannedCountDays = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/banned_count_days', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeBannedCountDay = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/banned_count_days', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateBannedCountDay = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/banned_count_days/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteBannedCountDay = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/banned_count_days/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)