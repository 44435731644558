import React, { useState } from 'react'
import { Table } from 'antd'
import { Badge } from 'reactstrap'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import SignModal from './SignModal'

export default function DeliveriesTable({ deliveries, reload, pagination, setPagination }) {
	const [signId, setSignId] = useState(null)

	const columns = [
		{
			title: 'Remisión de Entrega',
			dataIndex: 'order',
			render: t => t.code
		},
		{
			title: 'Nombre de Cliente',
			dataIndex: 'customer',
			render: t => t.name
		},
		{
			title: 'Document',
			dataIndex: 'customer',
			render: t => t.document
		},
		{
			title: 'Celular',
			dataIndex: 'customer',
			render: t => t.mobile
		},
		{
			title: 'Email',
			dataIndex: 'customer',
			render: t => t.email
		},
		{
			title: 'Fecha de Firma de Entrega',
			dataIndex: 'delivered_at',
			render: t => t ? moment(t).format('YYYY-MM-DD HH:mm') : '-'
		},
		{
			title: 'Estado',
			dataIndex: 'delivered_at',
			render: (t, record) => t ? <Badge color="success">Firmada</Badge> : <Badge color="warning">Sin Firmar</Badge> 			
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={deliveries}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			<SignModal 
				visible={signId ? true : false}
				onClose={()=>setSignId(null)}
				deliveryId={signId}
				reload={reload}
			/>			
		</React.Fragment>
	)
}

//<Button color="primary" size="sm" onClick={()=>setSignId(record.id)}>Firmar</Button>