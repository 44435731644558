import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ProductPromisesList from './ProductPromisesList'

export default function ProductPromises() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/product_promises" component={ProductPromisesList} />
				
				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}