import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import DeliveriesList from './DeliveriesList'

export default function Deliveries() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/deliveries" component={DeliveriesList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}