import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import ProductPromisesTable from './partials/ProductPromisesTable'
import NewProductPromise from './partials/NewProductPromise'
import { getProductPromises } from './services'
import { handleError } from '../../helpers'

export default function ProductPromisesList() {
	const [productPromises, setProductPromises] = useState(null)

	useEffect(() => {
		!productPromises && getProductPromises()
			.then(res => setProductPromises(res.data.data))
			.catch(error => handleError(error))
	}, [ productPromises ])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Promesa de Entrega</CardTitle>
							<CardSubtitle>Promesa de entregas de productos</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewProductPromise reload={()=>setProductPromises(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Promesas de Entrega</CardTitle>
							<CardSubtitle>Administre las promesas de entrega creadas</CardSubtitle>
						</CardHeader>
						<CardBody>
							<ProductPromisesTable 
								productPromises={productPromises} 
								reload={()=>setProductPromises(null)} 
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>	
		</React.Fragment>
	)
}