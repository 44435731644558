import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle, Alert } from 'reactstrap'

import BannedDaysTable from './partials/BannedDaysTable'
import NewBannedDay from './partials/NewBannedDay'
import { getBannedDays } from './services'
import { handleError } from '../../helpers'

export default function BannedDaysList() {
	const [bannedDays, setBannedDays] = useState(null)

	useEffect(() => {
		!bannedDays && getBannedDays({
			sort: '-date'
		})
			.then(res => setBannedDays(res.data.data))
			.catch(error => handleError(error))
	}, [ bannedDays ])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Fecha No Hábil</CardTitle>
							<CardSubtitle>Fechas no hábiles para entregas</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewBannedDay reload={()=>setBannedDays(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="7">
					<Card>
						<CardHeader>
							<CardTitle>Listado de Fechas No Hábiles para Entrega</CardTitle>
							<CardSubtitle>Administre las fechas no hábiles para entrega</CardSubtitle>
						</CardHeader>
						<CardBody>
							<Alert color="warning">Por defecto no se tiene en cuenta los domingos</Alert>
							<BannedDaysTable
								bannedDays={bannedDays} 
								reload={()=>setBannedDays(null)} 
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>	
		</React.Fragment>
	)
}