import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function EditUserModal({ visible, onClose, user, reload }) {
	const { handleSubmit, errors, register, reset } = useForm()

	const onSubmit = values => {
		if(values.document === user.document) delete values.document
		updateUser(user.id, values)
			.then(() => {
				message.success('Usuario actualizado exitosamente.')
				reload()
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Usuario</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
							<Form.Control name="name" ref={register({ required: true })} defaultValue={user && user.name} />
							{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Documento de Identidad <span className="text-danger">*</span></Form.Label>
							<Form.Control name="document" ref={register({ required: true })} defaultValue={user && user.document} />
							{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Correo Electrónico (Opcional)</Form.Label>
							<Form.Control name="email" ref={register({})} defaultValue={user && user.email} />
						</Form.Group>
						<Form.Group>
							<Form.Label>Estado</Form.Label>
							<Form.Control as="select" name="active" ref={register({})} defaultValue={user && user.active}>
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</Form.Control>
						</Form.Group>
						<Button type="submit" color="primary">Actualizar Usuario</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}