import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import BannedCountDaysList from './BannedCountDaysList'

export default function BannedCountDays() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/banned_count_days" component={BannedCountDaysList} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}