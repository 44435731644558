import React, { useState } from 'react'
import { message, Table, Tooltip, Divider, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditProductPromiseModal from './EditProductPromiseModal'
import { deleteProductPromise } from '../services'
import { handleError } from '../../../helpers'

export default function ProductPromisesTable({ productPromises, reload }) {
	const [editPrductPromise, setEditPrductPromise] = useState(null)

	const handleDelete = id => {
		deleteProductPromise(id)
			.then(() => {
				message.success('Promesa eliminada exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Linea de Producto',
			dataIndex: 'keyword',
		},
		{
			title: 'Medidas Estandar',
			dataIndex: 'regular_size',
			render: t => `${t} días`
		},
		{
			title: 'Medidas Especiales',
			dataIndex: 'custom_size',
			render: t => `${t} días`
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={() => setEditPrductPromise(record)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Popconfirm
						title="Esta acción no se puede revertir"
						okText="Eliminar"
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleDelete(record.id)}
					>
						<Tooltip title="Eliminar">
							<FontAwesomeIcon 
								className="text-link"
								icon={faTrash}
							/>
						</Tooltip>
					</Popconfirm>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={productPromises}
				columns={columns}
				rowKey={record => record.id}
				size="middle"
				loading={!productPromises}
			/>

			<EditProductPromiseModal
				visible={editPrductPromise ? true : false}
				onClose={() => setEditPrductPromise(null)}
				productPromise={editPrductPromise}
				reload={reload}
			/>
		</React.Fragment>
	)
}