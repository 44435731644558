import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'

import DeliveriesTable from './partials/DeliveriesTable'
import { getDeliveries } from './services'
import { handleError } from '../../helpers'
import { Divider } from 'antd'
import DeliveriesExportModal from './partials/DeliveriesExportModal'

export default function DeliveriesList() {
	const [deliveries, setDeliveries] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filterType, setFilterType] = useState('order.code')
	const [filterValue, setFilterValue] = useState('')
	const [showExportModal, setShowExportModal] = useState(false)

	useEffect(() => {
		!deliveries && getDeliveries({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'customer,order'
		})
			.then(res => setDeliveries(res.data.data))
			.catch(error => handleError(error))
	}, [deliveries, filterType, filterValue, pagination])

	return (
		<React.Fragment>
			<Card>
				<CardHeader>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={filterType}
									onChange={e => setFilterType(e.target.value)}
								>
									<option value="order.code">Buscar por número de remisión</option>
									<option value="customer.document">Buscar por cédula del cliente</option>
									<option value="customer.name">Buscar por nombre del cliente</option>
									<option value="customer.mobile">Buscar por celular del cliente</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={filterValue}
								onChange={e => setFilterValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setDeliveries(null)}>Buscar</Button>
							</InputGroup.Append>
							<Divider type="vertical" />
							<p className="text-link mb-0" onClick={() => setShowExportModal(true)}>Exportar</p>
						</InputGroup>
					</div>
					<CardTitle>Listado de Firmas de Entrega</CardTitle>
					<CardSubtitle>Entregas firmadas desde el aplicativo</CardSubtitle>
				</CardHeader>
				<CardBody>
					<DeliveriesTable 
						deliveries={deliveries} 
						reload={()=>setDeliveries(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showExportModal && (
				<DeliveriesExportModal 
					visible
					onClose={() => setShowExportModal(false)}
				/>
			)}
		</React.Fragment>
	)
}