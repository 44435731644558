import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeProductPromise } from '../services'
import { handleError } from '../../../helpers'

export default function NewProductPromise({ reload }) {
	const { handleSubmit, errors, register, reset } = useForm()

	const onSubmit = values => {
		console.log(values)
		storeProductPromise(values)
			.then(() => {
				message.success('Fecha agregada exitosamente')
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Producto <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						name="keyword"
						ref={register({ required: true })}
					/>
					{ errors.keyword && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Días para Medidas Estandar <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						type="number"
						name="regular_size"
						ref={register({ required: true })}
					/>
					{ errors.regular_size && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Días para Medidas Especiales <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						type="number"
						name="custom_size"
						ref={register({ required: true })}
					/>
					{ errors.custom_size && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Button type="submit" color="primary">Agregar Promesa de Entrega</Button>
			</Form>
		</React.Fragment>
	)
}