import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Table, Tooltip, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import NewRateForm from './NewRateForm'
import { deleteCityRouteRate } from '../services'
import { currency, handleError } from '../../../helpers'

export default function CityRouteRatesModal({ visible, onClose, cityRoute, reload }) {

	const handleDelete = id => {
		deleteCityRouteRate(id)
			.then(() => {
				message.success('Tarifa eliminada')
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Valor de Tarifa',
			dataIndex: 'value',
			render: t => `$${currency(t)}`
		},
		{
			title: 'Descripción',
			dataIndex: 'description'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea eliminar la tarifa?"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={() => handleDelete(record.id)}
						>
							<FontAwesomeIcon icon={faTrash} className="text-primary" />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Tarifas de la Ruta</ModalHeader>
				<ModalBody>
					<Table 
						dataSource={cityRoute && cityRoute.city_route_rates}
						loading={!cityRoute}
						columns={columns}
						rowKey={record => record.id}
						size="small"
					/>
					<div style={{ maxWidth: '350px' }}>
						<NewRateForm 
							city_route_id={cityRoute && cityRoute.id} 
							reload={() => {
								reload()
								onClose()
							}} 
						/>
					</div>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}